.border-right {
  border-right: 1px solid #cecece;
}
.bordered {
  border: 1px solid #cecece;
}
.card {
  box-shadow: 3px 4px 3px 1px rgba(224,224,224,0.48) !important;
}
.client-prefix {
  max-width: 4em !important;
}
.client-suffix {
  max-width: 6em !important;
}
.client-tab {
  border-color: white !important;
  background-color: white !important;
  box-shadow: #dcdcdc 1px -1px 1px 1px !important;
}
.member-box {
  border: 1px solid #cecece;
  padding: 10px 10px 0px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.member-delete {
  position: relative;
  top: -165px;
  left: -22px;
}
.ms-n1 {
  margin-left: -1rem !important;
}
.ms-n2 {
    margin-left: -2rem !important;
}
.mt-n1 {
  margin-top: -1rem !important;
}
.mt-n2 {
  margin-top: -2rem !important;
}
.nav-tabs {
    border-bottom: 0 !important;
}
.request-reasons, .meeting-services {
    min-height: 200px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
}
.rounded-danger {
  border: 0px;
  color: white;
  font-weight: bold;
  border-radius: 50% !important;
  background-color: var(--bs-danger) !important;
  padding: 0px 5px 0px 5px;
}
.rounded-danger:hover {
  background-color: #85000e;
  box-shadow: 0 0 2px #85000e;
}
.schedule-minute {
  height: 1px;
}
.schedule-half-hour {
  border-bottom: 1px solid #cccccc;
  height: 20px;
}
.schedule-full {
  padding-left: 3px;
  background-color: #ffefd1;
  border: 1px solid #969696;
}
.schedule-full:hover {
  z-index: 100;
}
.schedule-show {
  height: 75vh;
  overflow: scroll;
}
.w-33 {
  width: 33.3333333333% !important;
}
#client-tabs {
    margin-left: 10px;
}
#client-tabs .nav-link.active {
  background-color: white !important;
}
.feather-red {
    color: #D40303 !important;
    stroke: #D40303 !important;
}
.cursor {
    cursor: pointer;
}
#sps-documents {
    z-index: 100;
    position: absolute;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    right: 3rem;
    min-width: 15% !important;
    box-shadow: 3px 4px 3px 1px rgba(224,224,224,0.48) !important;
    overflow-y: scroll;
    overflow-x: hidden;
}
.sps-document-item {
    margin-bottom: 5px;
    text-align: left;
}
.record-row:hover {
    background-color: lightgray !important;
}
.record-row-light:hover{
    background-color: #f1f1f1 !important;
}
.detail-heading {
    display: flex;
}
.detail-toggle {
    float: right;
    margin-top: -1rem !important;
}
.-mt-1 {
    margin-top: -1rem !important;
}
#pending-documents, #queued-documents {
    min-width: 400px;
}
.read-notification {
    cursor: pointer;
    width: 20px;
    height: 20px;
}
.read-notification:hover {
    opacity: 0.5;
}
.notification-row:hover {
  background-color: #dbdbdb !important;
}
.notification-link:hover {
  text-decoration: none;
}
#last-notifications {
  width: 450px;
}

.dataTables_length label {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Mírné odsazení mezi prvky */
}

.dataTables_filter {
  float: right !important;
  text-align: right !important;
}

.dataTables_length {
  float: left !important;
}
#move-page-up {
  position: fixed;
  top: 90;
  left: 90;
  width: 45px;
  height: 45px;
  background-color: var(--bs-primary);
  opacity: 0.8;
  color: white;
  border-radius: 50%;
  padding: 0px 5px 0px 5px;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  box-shadow: 5px 5px 5px lightgrey;
  cursor: pointer;
}
.dataTables_filter {
  float: right;
  text-align: right;
}
.dataTables_paginate {
  float: right;
  text-align: right;
}
#topbar-profile {
  margin-top: -3px;
  margin-left: 20px;
}
#topbar-profile::after {
  margin-top: -5px;
}
.-mt-5px {
  margin-top: -5px !important;
}
.logo {
  max-width: 125px;
}
.info-box-open {
  cursor: pointer;
}
.info-box {
  position: absolute;
  margin-left: 150px;
  margin-top: -28px;
  padding: 10px;
  font-size: 12px;
  color: black;
  font-weight: thin;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: white;
}
.horizontal-scroll {
  overflow-x: scroll;  
  overflow-y: visible;
  transform: rotate(180deg);
  margin-left: 20px;
}
.horizontal-scroll table {
  transform: rotate(-180deg);
}
.chevrons-up {
  width: 35px;
  height: 35px;
}
input[type="date"] {
    min-width: 10ch !important;
}
#metadata-box {
  position: absolute;
  margin-top: -50px;
  z-index: 100;
  min-width: 550px;
}
#show-metadata {
  cursor: pointer;
  margin-top: -4px;
}
#workplace-dropdown {
  position: absolute;
  right: 165px;
  margin-top: 5px;
  z-index: 100;
}
